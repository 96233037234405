import React, { useState, useEffect,useRef, useReducer, useContext } from 'react'
import logo from '../img/logo.png';
import base from '../img/base.png';
import cover from '../img/cover.png';
import body from '../img/h_body.png';
import hand from '../img/h_hand.png';
import mizu from '../img/mizu.gif';
import fukidashi from '../img/fukidashi.png';
import philosophyImage from '../img/about.png';

import reload from '../img/reload.png';
import spk from '../img/spk.png';
import kf from '../img/kf.png';
import s01 from '../img/s01.png';
import info from '../img/info.png';
import ai from '../img/ai.png';
import loading from '../img/loading.gif';
import { IconContext } from 'react-icons' //IconContextをインポート
import { IoMdCloseCircle } from "react-icons/io";

const Main = () => {
    const [caption, setCaption] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [imgURL, setImgURL] = React.useState('https://hagumi-api.com/media/flower/flower_end.png')
    const [coverFlg, setCoverFlg] = React.useState(false)
    const [loadingFlg, setLoadingFlg] = React.useState(true)
    const [flowerFlg, setFlowerFlg] = React.useState(true)
    const [infoFlg, setInfoFlg] = React.useState(false)
    const [flowerName, setFlowerName] = React.useState('flowerName')
    const inputRef = useRef();
    let floorName_tmp = ''
    
    const info_func = () => {
        setInfoFlg(true)
    }

    const reload_func = () => {
        
        setCoverFlg(true)
        setLoadingFlg(true)
        setFlowerFlg(true)
    }
    const reload_func2 = (floorName) => {

        setFlowerName(floorName+"を<br/><span class='green-text'>はぐくみ中...</span>")

        setLoadingFlg(false)
        fetch("https://hagumi-api.com/flower_word", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ msg: floorName }) // msg に floorName を設定して送信
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("サーバーからエラーレスポンスが返されました。");
            }
            return response.text(); // テキスト形式でレスポンスを取得
        })
        .then(data => {
            //// サーバーからの応答をコンソールに表示
            console.log(data);
            if(data == '花、植物を入力してください'){
              //終わり
              setCoverFlg(false)
              setLoadingFlg(true)
              setFlowerFlg(false)
              setFlowerName('花、植物を入力してください!<br/>(花,植物,果物,野菜等...)')

              return;

            }
            //floor_g(data);
            //5秒後にfloor_gを実行
            setTimeout(() => {
                floor_g(data);
            }, 5000);
 
        })
        .catch(error => {
            console.error("エラーが発生しました:", error);
            setCoverFlg(false)
            setLoadingFlg(true)
           
        });

    }
    const floor_g = (floorName_en) => {

        setCoverFlg(false)
        setFlowerFlg(false)
        adjustFontSize()
        fetch("https://hagumi-api.com/flower", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ msg: floorName_en }) // msg に floorName_en を設定して送信
        })
        .then(response => {

            if (!response.ok) {
                throw new Error("サーバーからエラーレスポンスが返されました。");
            }
            return response.text(); // テキスト形式でレスポンスを取得
        })
        .then(data => {
            console.log(data); // サーバーからの応答をコンソールに表示
            //ランダムの数値を生成
            const random = Math.floor(Math.random() * 10000000);
            setImgURL('https://hagumi-api.com/media/flower/flower_end.png' + '?' + random)
            setCoverFlg(false)
            setLoadingFlg(true)
            setFlowerFlg(true)
            

        })
        .catch(error => {
            console.error("エラーが発生しました:", error);
            setCoverFlg(false)
            setLoadingFlg(true)
        });
    }
    const banner_func = (path) => {
        //新規ウィンドウで開く
        window.open(path, '_blank');
    }

    const bakMenu = () => {
        setCoverFlg(false)
        setInfoFlg(false)

    }




    useEffect(() => {
        const rnd = Math.floor(Math.random() * 10000000);
        setImgURL('https://hagumi-api.com/media/flower/flower_end.png' + '?' + rnd)
        //https://arai-chatbot.com/media/web/title.txt
        fetch('https://arai-chatbot.com/media/web/title.txt')
            .then(response => response.text())
            .then(text => setTitle(text))
        //https://arai-chatbot.com/media/web/caption.txt
        fetch('https://arai-chatbot.com/media/web/caption.txt')
            .then(response => response.text())
            .then(text => setCaption(text))


            // 初回読み込み時にも実行
            
       
    }, []);

    function adjustFontSize() {
      const fukidashi = document.getElementById("fukidashi");
      if (!fukidashi) return; // fukidashiが存在しない場合は終了

      const boxWidth = fukidashi.offsetWidth;
      const cmtBox = document.getElementById("cmtBox");
      if (cmtBox) {
        cmtBox.style.width = `${boxWidth}px`;
        const fontSize = boxWidth * 0.06;
        document.getElementById("cmt").style.fontSize = `${fontSize}px`;
      }

    //id=cmtのpを取得
      const cmt = document.getElementById("cmt");
      if (cmt) {
        //文字を変更
        cmt.textContent = flowerName
      }
      window.addEventListener("resize", adjustFontSize);
    }



    useEffect(() => {

      
        //setFlowerName("花言葉：" + floorName_tmp);
    
        // クリーンアップ
        
      }, [flowerFlg]); // flowerFlgの変化を監視

      
      


  




    return (

        <div className='main_box'>
           {infoFlg &&  <div className='cover'>
            <div className="info">
            <IconContext.Provider value={{ color: '#fff', size: '82px' }} >
                        <IoMdCloseCircle className='shdowIcon' onClick={bakMenu} />

            </IconContext.Provider>
            <p className="company-copy">
          自社プロダクツの企画、開発、運営を中心に<br/>
          デザインからプログラムまでを行う<br />
          クリエィティブカンパニーです。
        </p>
        <img src={philosophyImage} alt="Philosophy" className="philosophy-image" />
        <table className="corporate-data">
          <thead>
            <tr>
              <th colSpan="2">CORPORATE DATA</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>商号</td>
              <td>株式会社葉組 ha-gumi inc.</td>
            </tr>
            <tr>
              <td>設立</td>
              <td>2015年12月7日</td>
            </tr>
            <tr>
              <td>代表</td>
              <td>吉川祐二郎</td>
            </tr>
            <tr>
              <td>主な事業内容</td>
              <td>
                自社プロダクツ開発/デジタルコンテンツ企画・制作及び運営/UIデザイン/UX設計・UXコンサル/ソフトウェア設計・制作/スマートフォンアプリケーション開発/その他付随する業務
              </td>
            </tr>
            <tr>
              <td>資本金</td>
              <td>400万円</td>
            </tr>
            <tr>
              <td>主要取引先 (順不同敬称略)</td>
              <td>
                日本電気株式会社(NEC)/文部科学省-スポーツ庁/ピー・ディー・シー株式会社/コニカミノルタジャパン/パルコデジタルマーケティング/株式会社高島屋/株式会社アダストリア/オンワード梶山/富士通デザイン/株式会社三越伊勢丹/日工テクニカルサービス株式会社/NECネクサソリューションズ株式会社/フロンティアインターナショナル/株式会社五箱/株式会社スリー/株式会社リモード
              </td>
            </tr>
            <tr>
              <td>本社</td>
              <td>東京都目黒区中目黒1-4-6 (受付2階)</td>
            </tr>
            <tr>
              <td>TEL</td>
              <td>03-6451-0261</td>
            </tr>
            <tr>
              <td>FAX</td>
              <td>03-6451-0262</td>
            </tr>
          </tbody>
        </table>
        {/* Access Mapセクション */}
        <div className="access-map">
          <h2>Access map</h2>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.316697656082!2d139.6998015161041!3d35.644568180202896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b4631df7d2b%3A0x1b642b0b1cb7512a!2z44CSMTUzLTAwNjEg5p2x5Lqs6YO955uu6buS5Yy65Lit55uu6buS77yR5LiB55uu77yU4oiS77yW!5e0!3m2!1sja!2sjp!4v1521284111582"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            style={{ border: 0 }}
          ></iframe>
        </div>
      </div>


            </div>
}
 
            {coverFlg && <div className='cover'>
                {loadingFlg &&<div className='cover_contents'>
                    <IconContext.Provider value={{ color: '#fff', size: '52px' }} >
                        <IoMdCloseCircle className='shdowIcon' onClick={bakMenu} />

                    </IconContext.Provider>
                    <p id="cmt">何を育てたいですか？<br/>(花,植物,果物,野菜等...)</p>
                    <input type="text" id="input" ref={inputRef} placeholder="ex.)ひまわり、サボテン等" />
                    <div className='btn' id='footers' onClick={() => reload_func2(inputRef.current.value)}>
                        <p>Generate</p>
                    </div>
                </div>}
                {loadingFlg && <img src={ai} id="ai" alt="" />}
                {!loadingFlg && <img src={loading} id="loading" alt="" />}
                
            </div>
            }
            <div className='info_box' onClick={info_func}>
                <img src={info} alt="" />

            </div>
            <div className='main_contents'>
                <div className='left_contents'>
                    <div className="left_main">
                        <img src={logo} alt="" />
                        <h1>{title}</h1>
                        <p>{caption}</p>
                    </div>
                </div>
                <div className='right_contents'>
                    <div className='right_main'>
                        <div id="img_box">
                        {flowerFlg && <img src={body} id="body" alt="" />}
                            <img src={base} id="base" alt="" />
                            
                           {!flowerFlg && <img src={mizu} id="mizu" alt="" />}
                           {flowerFlg && <img src={imgURL} id="floor" alt="" />}
                            <img src={cover} id="cover" alt="" />
                            {flowerFlg && <img src={hand} id="hand" alt="" />}
                            {!flowerFlg && <img src={fukidashi} id="fukidashi" alt="" />}
                            {!flowerFlg && (
                              <div id="cmtBox">
                                <div id="cmt">
                                  <p dangerouslySetInnerHTML={{ __html: flowerName }}></p>
                                </div>
                              </div>
                            )}
                          
                        </div>
                        <img src={ai} id="ai" alt="" />
                        <img src={reload} id="reload" alt="" onClick={reload_func} />
                    </div>

          

                </div>

            </div>
            <div className='footer'>
                <div className='fotter_header'>
                    <p>PRODUCTS</p>

                </div>
                <div className='banner'>
                    <img src={s01} onClick={() => banner_func("https://ha-gumi.jp/dive2/")} alt="" />
                    <img src={spk} onClick={() => banner_func("https://ha-gumi.jp/dive2/")} alt="" />
                    <img src={kf} onClick={() => banner_func("https://ha-gumi.jp/dive2/")} alt="" />



                </div>
            </div>

        </div>

    )
}

export default Main